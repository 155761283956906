import React from 'react'
import {Container} from '@mui/material';
import '../style_sheets/common.css';

const Footer = () => {
  return (
    <Container >
        <hr className="divider"></hr>
        <p style={{textAlign:"center"}}> © 2023 飒飒不太飒 </p>
    </Container>
  )
}

export default Footer