import { useEffect, useState } from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Fade, Fab, Box, useScrollTrigger } from '@mui/material';

export const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    
    const toggleVisibility = () => {
        if (window.pageYOffset > 300){
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop =() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        }

    }, []);
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
      });

    return (
        <div>
           <Fade in={trigger}>
                <Box
                    onClick={scrollToTop}
                    role="presentation"
                    sx={{ position: 'fixed', bottom: 50, right: 25 }}
                >
                    <Fab size="small" aria-label="scroll back to top">
                        <KeyboardArrowUpIcon />
                    </Fab>

                </Box>    
           </Fade>
                
                
            
        </div>
    )
};