import React from 'react';
import Chip from '@mui/material/Chip';
import {Avatar} from '@mui/material';

const LiveStatusBadge = ({statusCode}) => {

    var statusString = "";
    if(statusCode === 2){
        statusString = "轮播中";
    }
    else if (statusCode === 1){
        statusString = "直播中";
    }
    else {
        statusString = "未开播";  //status code should be 0
    }

    const handleClick = () => {
        window.open('https://live.bilibili.com/23684492');
    };


    const biliLogo = require("../assets/icons/bili_logo.png");
    return (
        <div>
            {statusString === "未开播" 
                ? <Chip avatar={<Avatar src={biliLogo}/>} label={statusString} size="medium" variant="outlined"  onClick={handleClick} /> //未开播
                : statusString === "直播中" 
                    ? <Chip avatar={<Avatar src={biliLogo}/>} label={statusString} size="medium" variant="outlined" color="success"  onClick={handleClick} /> //直播
                    : <Chip avatar={<Avatar src={biliLogo}/>} label={statusString} size="medium" variant="outlined" color="warning"  onClick={handleClick} /> //轮播
            }
        </div>
    )
}

export default LiveStatusBadge;