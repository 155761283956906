import '../style_sheets/header.css'
import '../style_sheets/common.css'
import LiveStatusBadge from './LiveStatusBadge'
import React from 'react'
import { Container, Button, Avatar, Stack, Typography } from '@mui/material';

const avatar = require('../avatar.jpg');

const Header = ({
  liveStatus
}) => {
  return (

    <Container maxWidth="lg">
        
        <div className='Intro'>
          <Stack
          spacing={{ xs: 1, sm: 2, md: 2 }}
          justifyContent="center"
          alignItems="center"
          >
            <Avatar className="Avatar" alt="飒飒不太飒" src={avatar} sx={{ width: 120, height: 120, top: 10 }}/>
            
            <Typography variant="h6" gutterBottom component="div">
              飒飒不太飒
            </Typography>
             
            <Typography variant="caption" display="block" gutterBottom >
            国粤社畜搬砖唱播/温柔且善良/但易燃易爆炸/可爱只给跟我同频的人
            </Typography>

            <Typography variant="caption" gutterBottom >
            直播时间：晚9 请多关注动态
            </Typography>

            <Typography variant="caption" gutterBottom >
            点击歌名可以复制该歌曲点歌弹幕哦
            </Typography>
            
            <div className='btnStackDiv'>
              <Stack id="homeBtnStack" 
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              justifyContent="center"
              alignItems="center"
              >
                
                <LiveStatusBadge  statusCode={liveStatus} />
                <Button id="" 
                size="small"
                variant="outlined" 
                color="secondary" 
                href="https://space.bilibili.com/16774837/" 
                target="_blank">
                  飒飒のB站空间
                </Button>
              </Stack>
            </div>
          </Stack>
        </div>
    </Container>
    
  )
}

Header.defaultProps = {
    textAlign: 'center'
}





export default Header